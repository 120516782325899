<template>
  <div>
    <h4 class="mb-4 font-weight-semibold">{{ title }}</h4>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="table-responsive">
          <table class="table row table-borderless w-100 m-0 text-nowrap">
            <tbody class="col-lg-12 col-xl-12 p-0" style="    overflow: hidden;">
              
              <tr v-for="(item, i) in list" :key="'experience' + i">
                <td style="padding: 3px" v-if="i <= 4">
                  <li style="list-style: disc;white-space: initial;">
                    {{ item.title }}
                  </li>
                </td>
              </tr>
              <div></div>
              <div id="loadMore" @click="showMore" v-if="list.length >= 4">
                <i class="fa fa-angle-down" aria-hidden="true"></i>

              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      :id="'showMores'+s"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="box-shadow: 0px 0px 20px 0px #bb7494;" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ title }}
            <button class="btn btn-primary" @click="closeModal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          </div>
          <div
            class="modal-body"
            id="content"
            style="max-height: 450px; overflow-y: scroll"
          >
            <tr v-for="(item, i) in list" :key="'experience' + i">
              <td style="padding: 3px">
                <li style="list-style: disc;white-space: initial;">
                  {{ item.title }}
                </li>
              </td>
            </tr>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary w-100" @click="closeAndOpenAppointment">Randevu Al</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="videoModal"
    >
      <div class="modal-dialog modal-lg w-100" role="document">
        <div class="modal-content">
          <div class="modal-body" id="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#loadMore {
  height: 40px;
  overflow-y: hidden;
  text-align: center;
  font-size:20px;
}
#loadMore:hover {
cursor: pointer;
}
</style>
<script>
export default {
  props: ["title", "list","s"],
  created() {},
  data() {
    return {
      index: null,
      file: "",
    };
  },
  methods: {
    closeModal(){
      $("#showMores"+this.s).modal("hide");
    },
    closeAndOpenAppointment(){
      $("#showMores"+this.s).modal("hide");
      $("html, body").animate(
        {
          scrollTop: $("#sidebarList").offset().top - 20,
        },
        0
      );
      document.getElementById("append").style.display = "block";
      document.getElementById("profile_sidebar").classList.add("activeSelect");
      return false;
    },
    showMore() {
      
      $("#showMores"+this.s).modal("show");
      document.getElementById('body').style='overflow:hidden !important'
    },
    showInfo(item) {
      this.index = item;
      this.file = item.file;
      document.getElementById("content").innerHTML =
        '<iframe height="500" src="https://storage.terapivitrini.com/' +
        this.file +
        '" width="100%"/>';
      setTimeout(() => {
        $("#videoModal").modal("show");
      }, 500);
    },
  },
};
</script>