<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Hizmet</h3>
        <div style="position: absolute; right: 10px">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin">
            <i
              class="fa fa-info-circle"
              style="font-size: 25px; color: rgb(187, 116, 148)"
            ></i>
          </button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/hizmet.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Hizmet Ara ve Ekle</label>
              <select
                class="form-control select2-show-search border-bottom-0 w-100"
                data-placeholder="Select"
                name="selectedExperience"
                id="selectedExperience"
              >
                <optgroup label="Hizmet Seçiniz">
                  <option selected disabled value="0">
                    Hizmet Ara ve Ekle
                  </option>
                  <option
                    v-for="(item, i) in serviceList"
                    :key="i"
                    :value="item"
                  >
                    {{ item.experience_name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Seçili Hizmetler</label>
              </div>
              <div
                class="contatiner"
                id="sortable"
                v-if="selectedList.length > 0"
              >
                <draggable
                  v-model="selectedList"
                  group="list-group"
                  tag="ul"
                  @start="drag = true"
                  @end="drag = false"
                  @change="log"
                  handle=".handle"
                >
                  <div
                    v-for="(item, i) in selectedList"
                    :key="i"
                    style="
                      border: 1px solid rgb(187 116 148);
                      margin: 0px;
                      margin-top: -1px !important;
                    "
                    :class="'list-group-item list-group-item-action'"
                  >
                    <h3>
                      <i class="fa fa-align-justify handle"></i>
                      {{ item.experience_name }}
                    </h3>
                    <a
                      class="btn btn-primary"
                      style="
                        cursor: pointer;
                        position: absolute;
                        right: 5px;
                        top: 6px;
                      "
                      @click="experienceRemove(i)"
                      ><i class="side-menu__icon si si-trash"></i
                    ></a>
                    <div
                      class="row"
                      style="
                        top: -1px;
                        right: 50px;
                        cursor: pointer;
                        padding-bottom: 10px;
                        padding-top: 10px;
                      "
                    >
                      <div class="col-sm-12 col-md-12 col-xl-12">
                        <div class="row">
                          <div class="col-sm-12 col-md-6 col-xl-6">
                            <label class="form-label">Hizmet Türü:</label>
                            <select class="form-control" v-model="item.type">
                              <option selected value="SEANS">SEANS</option>
                              <!--<option value="KUR">KUR</option>-->
                            </select>
                          </div>
                          <div class="col-sm-12 col-md-6 col-xl-6">
                            <label class="form-label">Ücret:</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="item.price"
                              placeholder="Seans Ücreti"
                            />
                          </div>
                          <div class="col-sm-12 col-md-12 col-xl-12">
                            <label class="form-label"
                              >Ödeme Yöntemi Ekle:</label
                            >
                            <div class="input-group">
                              <select
                                class="form-control"
                                :id="'paymentMethods' + i"
                                @change="addPaymentMethods(i)"
                              >
                                <option disabled selected>Seçiniz</option>
                                <option
                                  v-for="(
                                    item, index
                                  ) in experiencePaymentMethods"
                                  :key="index"
                                  :value="JSON.stringify(item)"
                                >
                                  {{ item.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-primary"
                                @click="addPaymentMethods(i)"
                              >
                                Ekle
                              </button>
                            </div>
                            <div class="row mt-2 mb-2">
                              <div
                                class="col-sm-12 col-md-4 col-xl-4"
                                v-for="(a, t) in item.selected_payments"
                                :key="t"
                              >
                                <div class="card card-body p-1">
                                  {{ a.title }}
                                  <button
                                    class="btn btn-white btn-sm"
                                    @click="removePaymentItem(i, t)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--<div class="col-sm-12 col-md-4 col-xl-4">
                          <label class="form-label">Fiyat Birimi:</label>
                          <select class="form-control" v-model="item.unit">
                            <option selected value="₺">Türk Lirası (₺)</option>
                            <option value="€">Euro (€)</option>
                            <option value="$">Dolar ($)</option>
                          </select>
                        </div>-->
                          <div
                            class="col-sm-12 col-md-12 col-xl-12"
                            v-if="false"
                          >
                            <label class="form-label">Hizmet Açıklaması:</label>
                            <textarea
                              class="form-control"
                              v-model="item.description"
                              placeholder="Hizmet açıklaması girin"
                            ></textarea>
                          </div>

                          <div class="col-sm-12 col-md-3 col-xl-3">
                            <div style="padding-left: 20px">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="item.face_to_face == 1 ? true : false"
                                @click="itemFace2FaceChange(i)"
                                :id="'face2Face' + i"
                              />
                              <label
                                class="form-check-label"
                                :for="'face2Face' + i"
                              >
                                Yüz Yüze Hizmet
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-3 col-xl-3">
                            <div style="padding-left: 20px">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="item.online == 1 ? true : false"
                                @click="itemOnlineChange(i)"
                                :id="'mainOnline' + i"
                              />
                              <label
                                class="form-check-label"
                                :for="'mainOnline' + i"
                              >
                                Online Hizmet
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-3 col-xl-3">
                            <div style="padding-left: 20px">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="item.home_service == 1 ? true : false"
                                @click="itemServiceChange(i)"
                                :id="'homeService' + i"
                              />
                              <label
                                class="form-check-label"
                                :for="'homeService' + i"
                              >
                                Evde Hizmet
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-3 col-xl-3">
                            <div style="padding-left: 20px">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="item.pre_meeting == 1 ? true : false"
                                @click="itemPreMeetingChange(i)"
                                :id="'preMeeting' + i"
                              />
                              <label
                                class="form-check-label"
                                :for="'preMeeting' + i"
                              >
                                Ücretsiz Ön Görüşme
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <button
                type="button"
                id="saveExperienceButton"
                @click="saveExperience"
                class="btn btn-primary mt-3 w-100"
              >
                Kaydet
              </button>
            </div>
            <hr />
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Hizmet Talep Et </label>
              </div>
              <button
                type="button"
                class="btn btn-primary w-100 mb-3"
                id="saveExperienceRequestButton"
                @click="requestExperience"
              >
                Hizmet Talep
              </button>
              <div class="list-group" v-if="requestExperinceList.length > 0">
                <div
                  v-for="(item, i) in requestExperinceList"
                  :key="i"
                  class="list-group-item list-group-item-action active"
                >
                  <template v-if="item.id">
                    {{ item.title }}
                  </template>
                  <template v-else>
                    <div class="row">
                      <input
                        type="text"
                        v-model="item.title"
                        placeholder="Hizmet başlığı girin"
                        class="col-md-6 form-control"
                      />
                      <div
                        class="btn col-md-6 form-control"
                        style="cursor: pointer"
                        @click="requestExperinceRemove(i)"
                      >
                        Sil
                      </div>
                    </div>
                  </template>
                </div>
                <button
                  type="button"
                  @click="saveRequestExperience"
                  id="saveRequestExperienceButton"
                  class="btn btn-primary"
                >
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from "../../ProfileDetail/Components/Info.vue";
import draggable from "vuedraggable";

export default {
  created() {
    document.title = "Hizmetlerim";
    let experienceInfo = {};
    this.$store.dispatch("serviceList", experienceInfo).then((value) => {
      this.serviceList = value;
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#content-app").offset().top - 60,
          },
          0
        );
      }, 500);
      $(".select2-show-search").select2({
        minimumResultsForSearch: "",
        placeholder: "Hizmetlerde ara....",
      });
    });
    this.$store.dispatch("serviceUserList", experienceInfo).then((value) => {
      if(value.payment_redirect){
              this.$vToastify.warning(
                "Hizmet eklemek için ödeme alma bilgileri doldurulmalı",
                "Uyarı!"
              );
              this.$router.push("education/subscribe");
            } else {
              this.selectedList = value.list;
              this.experiencePaymentMethods = value.experience_payment_methods;
              setTimeout(() => {
                $("html, body").animate(
                  {
                    scrollTop: $("#content-app").offset().top - 60,
                  },
                  0
                );
              }, 500);
            }
      
    });
    this.$store
      .dispatch("serviceUserRequestList", experienceInfo)
      .then((value) => {
        this.requestExperinceList = value;
      });
  },
  data() {
    return {
      options: {
        handle: ".handle",
        filter: ".disabled",
      },
      serviceList: [],
      selectedList: [],
      experiencePaymentMethods: [],
      requestExperinceList: [],
      search: "",
      drag: true,
    };
  },
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
    log() {
      var i = 1;
      this.selectedList.forEach((element) => {
        element.sort = i;
        i++;
      });
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    removePaymentItem(i, t) {
      this.selectedList[i].selected_payments.splice(t, 1);
      this.selectedList.push({});
      this.selectedList.pop();
    },
    itemPreMeetingChange(i) {
      if (this.selectedList[i].pre_meeting == 1) {
        this.selectedList[i].pre_meeting = 0;
      } else {
        this.selectedList[i].pre_meeting = 1;
      }
    },
    itemServiceChange(i) {
      if (this.selectedList[i].home_service == 1) {
        this.selectedList[i].home_service = 0;
      } else {
        this.selectedList[i].home_service = 1;
      }
    },
    itemOnlineChange(i) {
      if (this.selectedList[i].online == 1) {
        this.selectedList[i].online = 0;
      } else {
        this.selectedList[i].online = 1;
      }
    },
    itemFace2FaceChange(i) {
      if (this.selectedList[i].face_to_face == 1) {
        this.selectedList[i].face_to_face = 0;
      } else {
        this.selectedList[i].face_to_face = 1;
      }
    },
    addPaymentMethods(index) {
      let methods = JSON.parse(
        document.getElementById("paymentMethods" + index).value
      );
      let valid = true;
      this.selectedList[index].selected_payments.forEach((e) => {
        if (e.id == methods.id) {
          valid = false;
        }
      });
      if (valid) {
        if (methods.is_online == 1) {
          let _this = this;
          //ÖDEME ALMA DOLDURULMUŞ MU?
          this.$store.dispatch("getPaymentInfoIsDone").then((value) => {
            
            if (value) {
              _this.selectedList[index].selected_payments.push(methods);
              _this.selectedList.push({});
              _this.selectedList.pop();
            } else {
              this.$vToastify.warning(
                "Online ödeme almak için ödeme alma bilgileri doldurulmalı",
                "Uyarı!"
              );
              this.$router.push("education/subscribe");
            }
          });
        } else {
          this.selectedList[index].selected_payments.push(methods);
          this.selectedList.push({});
          this.selectedList.pop();
        }
      } else {
        this.$vToastify.warning("Ödeme yöntemi hizmette tanımlı!", "Uyarı!");
      }
    },
    saveExperience() {
      let priceControl = true;
      let paymentMethodControl = true;
      let interviewMethodControl=true;
      this.selectedList.forEach((element) => {
        console.log(element.selected_payments)
          if (element.selected_payments.length ==0) {
            paymentMethodControl = false;
          }
          if (element.price == null || element.price == 0) {
            priceControl = false;
          }
          if (element.home_service == 0 && element.face_to_face == 0 && element.online==0) {
            interviewMethodControl = false;
          }
        });
      
      if (priceControl && paymentMethodControl && interviewMethodControl) {
        document.getElementById("saveExperienceButton").disabled = true;
        document.getElementById("saveExperienceButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let sendList = [];
        this.selectedList.forEach((e) => {
          sendList.unshift({
            id: e.id,
            experince_id: e.experince_id,
            price: e.price,
            unit: e.unit,
            type: e.type,
            main: e.main,
            home_service: e.home_service,
            online: e.online,
            face_to_face: e.face_to_face,
            description: e.description,
            pre_meeting: e.pre_meeting,
            sort: e.sort,
            selected_payments: e.selected_payments,
          });
        });
        let experienceSave = {
          experience: JSON.stringify(sendList),
        };
        this.$store.dispatch("serviceUpdate", experienceSave).then((value) => {
          this.$vToastify.success(
            "Hizmetler başarılı bir şekilde kaydedildi",
            "Başarılı!"
          );
          document.getElementById("saveExperienceButton").disabled = false;
          document.getElementById("saveExperienceButton").innerHTML = "Kaydet";
        });
      } else {
        if (priceControl == false && paymentMethodControl == false) {
          this.$vToastify.warning(
            "Ücreti ve ödeme yöntemi girilmemiş hizmetleriniz var",
            "Başarılı!"
          );
        }
        else if (interviewMethodControl == false && paymentMethodControl == false) {
          this.$vToastify.warning(
            "Görüşme ve ödeme yöntemi girilmemiş hizmetleriniz var",
            "Başarılı!"
          );
        }
        else if (priceControl == false && paymentMethodControl == false && interviewMethodControl == false) {
          this.$vToastify.warning(
            "Ücreti, ödeme ve görüşme yöntemi girilmemiş hizmetleriniz var",
            "Başarılı!"
          );
        }
        else if (priceControl == false)
          this.$vToastify.warning(
            "Ücreti girilmemiş hizmetleriniz var",
            "Başarılı!"
          );
        else if (paymentMethodControl == false)
          this.$vToastify.warning(
            "Ödeme yöntemi eklenmemiş hizmetleriniz var",
            "Başarılı!"
          );
        else if (interviewMethodControl == false)
          this.$vToastify.warning(
            "Görüşme yöntemi eklenmemiş hizmetleriniz var",
            "Başarılı!"
          );
        
      }
    },
    saveRequestExperience() {
      let clearList = [];

      this.requestExperinceList.forEach((element, i) => {
        if (element.title != "") {
          clearList.unshift(element);
        }
      });
      if (priceControl && paymentMethodControl) {
        document.getElementById("saveRequestExperienceButton").disabled = true;
        document.getElementById("saveRequestExperienceButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let experienceSave = {
          experience: JSON.stringify(clearList),
        };
        this.$store
          .dispatch("serviceRequestUpdate", experienceSave)
          .then((value) => {
            document.getElementById(
              "saveRequestExperienceButton"
            ).disabled = false;
            document.getElementById("saveRequestExperienceButton").innerHTML =
              "Kaydet";
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
            } else {
              this.$vToastify.success(
                "Hizmet Talepleri başarılı bir şekilde kaydedildi",
                "Başarılı!"
              );
            }
          });
        
      }
    },
    requestExperience() {
      this.requestExperinceList.unshift({ title: "" });
    },
    experienceRemove(index) {
      this.selectedList.splice(index, 1);
    },
    requestExperinceRemove(index) {
      this.requestExperinceList.splice(index, 1);
    },
  },
  components: { Info, draggable },
  mounted() {
    this.backToTop();
    $(".select2-show-search").select2({
      minimumResultsForSearch: "",
      placeholder: "Hizmetlerde ara....",
    });
    let _this = this;
    $("#selectedExperience").on("select2:select", function (e) {
      let list = $("#selectedExperience").select2("data")[0].element._value;
      list.description = "";
      list.home_service = 0;
      list.type = "SEANS";
      list.price = 0;
      list.main = 0;
      list.online = 0;
      list.face_to_face = 0;
      list.description = "";
      list.pre_meeting = 0;
      list.sort = 0;
      list.selected_payments = [];
      list.unit = "₺";
      let a = $("#selectedExperience").select2("data")[0].element._value;
      let valid = true;
      _this.selectedList.forEach((e) => {
        if (e.experience_name == a.experience_name) {
          valid = false;
        }
      });
      if (valid) {
        _this.selectedList.unshift(
          $("#selectedExperience").select2("data")[0].element._value
        );
      } else {
        _this.$vToastify.warning("Hizmet zaten listenizde var!", "Uyarı!");
      }
    });
  },
};
</script>